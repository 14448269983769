import { createSlice } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { onApplicationError } from '../reducers/application';
import api from '../../utils/api';

const initialState = {
  isAuthenticated: false,
  isLoading: false,
  token: null,
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStarted(state) {
      state.isLoading = true;
    },
    logInCompleted(state, { payload }) {
      if (!payload) {
        state.isLoading = false;
      } else {
        Object.assign(state, {
          isLoading: false,
          isAuthenticated: true,
          ...payload,
        });
      }
    },
    logOut(state) {
      Object.assign(state, initialState);
    },
  },
});

export const { logOut } = authSlice.actions;

export const logIn = ({ email, password }) => async (dispatch) => {
  const { loginStarted, logInCompleted } = authSlice.actions;

  dispatch(loginStarted());

  try {
    const resp = await api.post('/auth/login', { email, password });

    batch(() => {
      dispatch(logInCompleted(resp));
    });
  } catch (e) {
    console.log('e', e);
    batch(() => {
      dispatch(logInCompleted());
      dispatch(onApplicationError({ error: e.message }));
    });
  }
};

export default authSlice.reducer;
