import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
import authReducer from '../slices/auth';
import usersReducer from '../slices/users';
import dataReducer from '../slices/data';
import portfolios from '../slices/portfolios';
import projectsReducer from '../slices/projects';
import application from './application';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

export default persistReducer(
  persistConfig,
  combineReducers({
    auth: authReducer,
    data: dataReducer,
    users: usersReducer,
    projects: projectsReducer,
    application,
    portfolios,
  })
);
