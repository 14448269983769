import { createSlice } from '@reduxjs/toolkit';
import {
  onApplicationMessage,
  onApplicationError,
} from '../reducers/application';
import api from '../../utils/api';

const initialState = {
  list: [],
  searchList: [],
  isFetching: false,
  isUpdating: false,
};

const authSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    fetchStarted(state) {
      state.isFetching = true;
    },
    fetchCompleted(state, { payload }) {
      state.isFetching = false;

      if (payload) {
        state.list = payload;
      }
    },
    updateStarted(state, { payload }) {
      state.isUpdating = true;

      const user = state.list.find((u) => u.id === payload.id);

      Object.assign(user, payload);
    },
    updateCompleted(state, { payload }) {
      const user = state.list.find((u) => u.id === payload.id);

      state.isUpdating = false;
      Object.assign(user, payload);
    },
  },
  extraReducers: {
    [onApplicationError]: (state) => {
      state.isFetching = false;
      state.isUpdating = false;
    },
  },
});

export const fetchUsers = () => async (dispatch) => {
  const { fetchCompleted, fetchStarted } = authSlice.actions;

  dispatch(fetchStarted());

  try {
    const resp = await api.get('/users');

    dispatch(fetchCompleted(resp));
  } catch (e) {
    dispatch(onApplicationError({ error: e.message }));
  }
};

export const updateUser = ({ id, ...rest }) => async (dispatch) => {
  const { updateCompleted, updateStarted } = authSlice.actions;

  dispatch(updateStarted({ id, ...rest }));
  try {
    const resp = await api.put(`/users/${id}`, rest);

    dispatch(updateCompleted(resp));
    dispatch(onApplicationMessage('User was updated.'));
  } catch (e) {
    dispatch(onApplicationError({ error: e.message }));
  }
};

export default authSlice.reducer;
