import { createSlice } from '@reduxjs/toolkit';
import {
  onApplicationError,
  onApplicationMessage,
} from '../reducers/application';
import api from '../../utils/api';

const initialState = {
  isLoading: false,
  list: [],
  currentLink: {
    isUpdating: false,
    link: null,
  },
};

export const portfoliosSlice = createSlice({
  name: 'portfolios',
  initialState,
  reducers: {
    fetchLinksStarted(state) {
      state.isLoading = true;
    },
    fetchLinksFinished(state) {
      state.isLoading = false;
    },
    fetchLinksCompleted(state, { payload }) {
      Object.assign(state, {
        isLoading: false,
        list: payload,
      });
    },
    fetchLinkCompleted(state, { payload: link }) {
      Object.assign(state, {
        isLoading: false,
        currentLink: {
          link,
        },
      });
    },
    clearCurrentLink(state) {
      state.currentLink = {
        isUpdating: false,
        link: null,
      };
    },
    saveLinkStarted(state) {
      state.currentLink.isUpdating = true;
    },
    saveLinkCompleted(state, { payload: link }) {
      state.currentLink.isUpdating = false;
      state.currentLink.link = link;
    },
    deleteLinkCompleted(state, { payload: deletedId }) {
      state.list = state.list.filter(({ id }) => id !== deletedId);
    },
    setPublicCompleted(state, { payload: updatedId }) {
      state.list = state.list.reduce((acc, link) => {
        if (updatedId === link.id) {
          return acc.concat([Object.assign(link, { isDefault: true })]);
        }

        return acc.concat([{ ...link, isDefault: false }]);
      }, []);
    },
  },
});

export const { clearCurrentLink } = portfoliosSlice.actions;

export const fetchPortfolios = () => async (dispatch) => {
  const { fetchLinksStarted, fetchLinksCompleted } = portfoliosSlice.actions;

  try {
    dispatch(fetchLinksStarted());
    const portfolios = await api.get('/links');

    dispatch(fetchLinksCompleted(portfolios));
  } catch (e) {
    dispatch(onApplicationError({ error: e.message }));
  }
};

export const fetchPortfolio = (id) => async (dispatch) => {
  const { fetchLinksStarted, fetchLinkCompleted } = portfoliosSlice.actions;

  try {
    dispatch(fetchLinksStarted());
    const link = await api.get(`/links/${id}`);

    dispatch(fetchLinkCompleted(link));
  } catch (e) {
    dispatch(onApplicationError({ error: e.message }));
  }
};

export const fetchPublicPortfolio = (id) => async (dispatch) => {
  const {
    fetchLinksStarted,
    fetchLinkCompleted,
    fetchLinksFinished,
  } = portfoliosSlice.actions;

  try {
    dispatch(fetchLinksStarted());
    const link = await api.get(`/links/public/${id}`);

    dispatch(fetchLinkCompleted(link));
  } catch (e) {
    dispatch(fetchLinksFinished());
    dispatch(onApplicationError({ error: e.message }));
  }
};

export const savePortfolio = (portfolio, history) => async (dispatch) => {
  const { saveLinkCompleted, saveLinkStarted } = portfoliosSlice.actions;
  const method = portfolio.id ? 'put' : 'post';
  const url = `/links${portfolio.id ? `/${portfolio.id}` : ''}`;

  try {
    dispatch(saveLinkStarted());
    const link = await api[method](url, portfolio);
    const message = portfolio.id
      ? 'Link successfully updated!'
      : 'Link successfully created!';

    dispatch(saveLinkCompleted(link));
    dispatch(onApplicationMessage({ message }));

    if (!portfolio.id) {
      history.replace('/admin/portfolios');
    }
  } catch (e) {
    dispatch(onApplicationError({ error: e.message }));
  }
};

export const deletePortfolio = (portfolioId) => async (dispatch) => {
  const { deleteLinkCompleted } = portfoliosSlice.actions;
  const url = `/links/${portfolioId}`;

  try {
    await api.delete(url);

    dispatch(deleteLinkCompleted(portfolioId));
    dispatch(onApplicationMessage({ message: 'Link successfully deleted!' }));
  } catch (e) {
    dispatch(onApplicationError({ error: e.message }));
  }
};

export const setPublic = (portfolioId) => async (dispatch) => {
  const url = `/links/default/${portfolioId}`;

  const { setPublicCompleted } = portfoliosSlice.actions;

  try {
    const { id } = await api.post(url);

    dispatch(setPublicCompleted(id));
    dispatch(onApplicationMessage({ message: 'Link successfully updated!' }));
  } catch (e) {
    dispatch(onApplicationError({ error: e.message }));
  }
};

export default portfoliosSlice.reducer;
