import React, { lazy, Suspense } from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import Spinner from './components/Spinner';
import { DataProvider } from './core';
import { history } from './utils';
import 'antd/dist/antd.css';
import './App.css';

const AdminContainer = lazy(() => import('./pages/Admin'));
const Customer = lazy(() => import('./pages/Customer'));

export default function App() {
  return (
    <div className="App">
      <DataProvider>
        <Router history={history}>
          <Suspense fallback={<Spinner fullpage />}>
            <Switch>
              <Route from="/admin" component={AdminContainer} />
              <Route from="/" render={(props) => <Customer {...props} />} />
            </Switch>
          </Suspense>
        </Router>
      </DataProvider>
    </div>
  );
}
