import React from 'react';
import classnames from 'classnames';
import './index.css';

export default function Spinner({ fullpage, center }) {
  const spinnerClasses = classnames('lds-dual-ring', {
    'lds-dual-ring--fullpage': fullpage,
  });

  const wrapperClasses = classnames('lds-dual-ring__wrapper', {
    'lds-dual-ring--center': center,
  });

  return (
    <div className={wrapperClasses}>
      <div className={spinnerClasses} />
    </div>
  );
}
