import axios from 'axios';
import { store } from '../core/store';
import { logOut } from '../redux/slices/auth';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 15000,
});

instance.interceptors.request.use(
  (config) => {
    const {
      auth: { token },
    } = store.getState();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => response.data,
  (error) => {
    console.log(error);
    if (!error.response) {
      return Promise.reject({ message: 'Unknown API Error' });
    }

    const {
      response: {
        data,
        config: { url },
      },
    } = error;

    if (data?.error?.httpCode === 401 && url !== '/auth/login') {
      return store.dispatch(logOut());
    }

    if (!data.message) {
      return Promise.reject({ message: data });
    }

    const { message } = data;
    const errorMessage = Array.isArray(message)
      ? message.reduce((all, curr) => `${all}\n${curr}`, '')
      : message;

    return Promise.reject({ message: errorMessage });
  }
);

export default instance;
