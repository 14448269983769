import { createAction, createReducer } from '@reduxjs/toolkit';

export const onApplicationError = createAction('onApplicationError');
export const onApplicationMessage = createAction('onApplicationMessage');
export const clearMessages = createAction('onApplicationMessageClear');

export default createReducer(
  {
    message: null,
  },
  {
    [onApplicationMessage]: (state, { payload }) => {
      state.message = {
        type: 'message',
        message: payload.message,
      };
    },

    [onApplicationError]: (state, { payload: { error } }) => {
      state.message = {
        type: 'error',
        message: error,
      };
    },
    [clearMessages]: (state) => {
      state.message = null;
    },
  }
);
