import { createSlice } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import {
  onApplicationError,
  onApplicationMessage,
} from '../reducers/application';
import api from '../../utils/api';

const initialState = {
  isLoading: false,
  tags: null,
  categories: null,
  dropdowns: {},
};

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    fetchDataStarted(state) {
      state.isLoading = true;
    },
    fetchDropdownsCompleted(state, { payload }) {
      state.dropdowns = payload;
    },
    fetchTagsCompleted(state, { payload }) {
      if (!payload) {
        state.isLoading = false;
      } else {
        Object.assign(state, {
          isLoading: false,
          tags: payload,
        });
      }
    },
    addTagCompleted(state, { payload }) {
      state.tags.push(payload);
    },
    saveTagCompleted(state, { payload }) {
      const tag = state.tags.find((tagItem) => tagItem.id === payload.id);

      Object.assign(tag, payload);
    },
    saveTagError(state, { payload }) {
      const tag = state.tags.find((tagItem) => tagItem.id === payload.id);

      Object.assign(tag, payload);
    },
    deleteTagCompleted(state, { payload }) {
      Object.assign(state, {
        tags: state.tags.filter((tag) => tag.id !== payload),
      });
    },
    fetchCategoriesCompleted(state, { payload }) {
      if (!payload) {
        state.isLoading = false;
      } else {
        Object.assign(state, {
          isLoading: false,
          categories: payload,
        });
      }
    },
    addCategoryCompleted(state, { payload }) {
      state.categories.push(payload);
    },
    saveCategoryCompleted(state, { payload }) {
      const category = state.categories.find(
        (categoryItem) => categoryItem.id === payload.id
      );

      Object.assign(category, payload);
    },
    deleteCategoryCompleted(state, { payload }) {
      Object.assign(state, {
        categories: state.categories.filter(
          (category) => category.id !== payload
        ),
      });
    },
  },
});

export const fetchDropdowns = () => async (dispatch) => {
  const { fetchDropdownsCompleted } = dataSlice.actions;

  try {
    const resp = await api.get('/data/dropdowns');

    dispatch(fetchDropdownsCompleted(resp));
  } catch (e) {
    dispatch(onApplicationError({ error: e.message }));
  }
};

export const fetchTags = () => async (dispatch) => {
  const { fetchDataStarted, fetchTagsCompleted } = dataSlice.actions;

  dispatch(fetchDataStarted());
  try {
    const resp = await api.get('/data/tags');

    dispatch(fetchTagsCompleted(resp));
  } catch (e) {
    dispatch(onApplicationError({ error: e.message }));
  }
};

export const addTag = ({ name, type }) => async (dispatch) => {
  const { addTagCompleted } = dataSlice.actions;

  try {
    const resp = await api.post('/data/tags', { name, type });

    batch(() => {
      dispatch(addTagCompleted(resp));
      dispatch(onApplicationMessage({ message: `Tag '${name}' added!` }));
    });
  } catch (e) {
    dispatch(onApplicationError({ error: e.message }));
  }
};

export const saveTag = ({ name, type, id }) => async (dispatch) => {
  const { saveTagCompleted, saveTagError } = dataSlice.actions;

  try {
    const resp = await api.put(`/data/tags/${id}`, { name, type });

    batch(() => {
      dispatch(saveTagCompleted(resp));
      dispatch(onApplicationMessage({ message: `Tag '${name}' saved!` }));
    });
  } catch (e) {
    dispatch(saveTagError({ id }));
    dispatch(onApplicationError({ error: e.message }));
  }
};

export const deleteTag = ({ id }) => async (dispatch) => {
  const { deleteTagCompleted } = dataSlice.actions;

  try {
    await api.delete(`/data/tags/${id}`);

    batch(() => {
      dispatch(deleteTagCompleted(id));
      dispatch(onApplicationMessage({ message: 'Tag deleted successfuly!' }));
    });
  } catch (e) {
    dispatch(onApplicationError({ error: e.message }));
  }
};
export const fetchCategories = () => async (dispatch) => {
  const { fetchDataStarted, fetchCategoriesCompleted } = dataSlice.actions;

  dispatch(fetchDataStarted());
  try {
    const resp = await api.get('/data/categories');

    dispatch(fetchCategoriesCompleted(resp));
  } catch (e) {
    dispatch(onApplicationError({ error: e.message }));
  }
};

export const addCategory = ({ name, type }) => async (dispatch) => {
  const { addCategoryCompleted } = dataSlice.actions;

  try {
    const resp = await api.post('/data/categories', { name, type });

    batch(() => {
      dispatch(addCategoryCompleted(resp));
      dispatch(onApplicationMessage({ message: `Category '${name}' added!` }));
    });
  } catch (e) {
    dispatch(onApplicationError({ error: e.message }));
  }
};

export const saveCategory = ({ name, type, id }) => async (dispatch) => {
  const { saveCategoryCompleted } = dataSlice.actions;

  dispatch(saveCategoryCompleted({ name, type, id }));
  try {
    const resp = await api.put(`/data/categories/${id}`, { name, type });

    batch(() => {
      dispatch(saveCategoryCompleted(resp));
      dispatch(onApplicationMessage({ message: `Category '${name}' saved!` }));
    });
  } catch (e) {
    dispatch(onApplicationError({ error: e.message }));
  }
};

export const deleteCategory = ({ id }) => async (dispatch) => {
  const { deleteCategoryCompleted } = dataSlice.actions;

  try {
    await api.delete(`/data/categories/${id}`);

    batch(() => {
      dispatch(deleteCategoryCompleted(id));
      dispatch(onApplicationMessage({ message: 'Category deleted!' }));
    });
  } catch (e) {
    dispatch(onApplicationError({ error: e.message }));
  }
};

export default dataSlice.reducer;
