import { createSlice } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import {
  onApplicationError,
  onApplicationMessage,
} from '../reducers/application';
import api from '../../utils/api';

const initialState = {
  isLoading: false,
  list: [],
  currentProject: {
    project: null,
  },
};

const projectsSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    projectsStarted(state) {
      state.isLoading = true;
    },
    projectsCompleted(state) {
      state.isLoading = false;
    },
    fetchProjectsCompleted(state, { payload }) {
      if (!payload) {
        state.isLoading = false;
      } else {
        Object.assign(state, {
          isLoading: false,
          list: payload,
        });
      }
    },
    fetchProjectCompleted(state, { payload }) {
      if (!payload) {
        state.isLoading = false;
      } else {
        Object.assign(state, {
          isLoading: false,
          currentProject: payload,
        });
      }
    },
    updateCompleted(state, { payload }) {
      state.isLoading = false;
      state.currentProject.project = payload;
    },
    onResetProject(state) {
      state.isLoading = false;
      state.currentProject.project = null;
    },
  },
});

export const addProject = (project, history) => async (dispatch) => {
  const { projectsStarted, updateCompleted } = projectsSlice.actions;

  try {
    dispatch(projectsStarted());
    const newProject = await api.post('/projects', project);

    batch(() => {
      dispatch(updateCompleted(newProject));
      dispatch(
        onApplicationMessage({
          message: `Project '${newProject.projectName}' saved!`,
        })
      );
    });
    history.push(`/admin/projects/${newProject.id}`);
  } catch (e) {
    dispatch(onApplicationError({ error: e.message }));
  }
};

export const fetchProjects = () => async (dispatch) => {
  const { projectsStarted, fetchProjectsCompleted } = projectsSlice.actions;

  try {
    dispatch(projectsStarted());
    const projects = await api.get('/projects');

    dispatch(fetchProjectsCompleted(projects));
  } catch (e) {
    dispatch(onApplicationError({ error: e.message }));
  }
};

export const fetchProject = (id) => async (dispatch) => {
  const { projectsStarted, fetchProjectCompleted } = projectsSlice.actions;

  try {
    dispatch(projectsStarted());
    const project = await api.get(`/projects/${id}`);

    dispatch(fetchProjectCompleted(project));
  } catch (e) {
    dispatch(onApplicationError({ error: e.message }));
  }
};

export const fetchPublicProject = (linkId, projectId) => async (dispatch) => {
  const {
    projectsStarted,
    fetchProjectCompleted,
    projectsCompleted,
  } = projectsSlice.actions;

  try {
    dispatch(projectsStarted());
    const project = await api.get(`/links/public/${linkId}/${projectId}`);

    dispatch(fetchProjectCompleted({ project }));
  } catch (e) {
    dispatch(projectsCompleted());
    dispatch(onApplicationError({ error: e.message }));
  }
};

export const resetProject = () => (dispatch) => {
  const { onResetProject } = projectsSlice.actions;

  dispatch(onResetProject());
};

export const saveProject = (id, data) => async (dispatch) => {
  const { projectsStarted, updateCompleted } = projectsSlice.actions;

  try {
    dispatch(projectsStarted());

    const project = await api.put(`/projects/${id}`, data);

    dispatch(updateCompleted(project));
    dispatch(
      onApplicationMessage({
        message: `Project '${project.projectName}' saved!`,
      })
    );
  } catch (e) {
    dispatch(onApplicationError({ error: e.message }));
  }
};

export const deleteProject = (id, history) => async (dispatch) => {
  const { onResetProject } = projectsSlice.actions;

  try {
    await api.delete(`/projects/${id}`);

    dispatch(onResetProject());
    history.push('/admin/projects');
    dispatch(
      onApplicationMessage({
        message: 'Project deleted!',
      })
    );
  } catch (e) {
    dispatch(onApplicationError({ error: e.message }));
  }
};

export const assignProject = (id, data) => async (dispatch) => {
  const { projectsStarted, updateCompleted } = projectsSlice.actions;

  try {
    dispatch(projectsStarted());

    const project = await api.post(`/projects/${id}/assign`, data);

    dispatch(updateCompleted(project));
    dispatch(
      onApplicationMessage({
        message: `Project '${project.projectName}' assigned to ${project.currentOwner.fullName}!`,
      })
    );
  } catch (e) {
    dispatch(onApplicationError({ error: e.message }));
  }
};

export default projectsSlice.reducer;
