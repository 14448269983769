import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from '../redux/reducers';

const isProduction = process.env.NODE_ENV !== 'development';

const middleware = [thunkMiddleware];

if (!isProduction) {
  middleware.push(logger);
}

const _store = configureStore({
  reducer: rootReducer,
  middleware: [
    ...middleware,
    ...getDefaultMiddleware({ serializableCheck: false }),
  ],
  preloadedState: {},
});

export const store = _store;

if (!isProduction && module.hot) {
  module.hot.accept('../redux/reducers', () =>
    store.replaceReducer(rootReducer)
  );
}

export const persistor = persistStore(_store);
