import Joi from 'joi';
import { createBrowserHistory } from 'history'; // eslint-disable-line import/no-extraneous-dependencies

export const history = createBrowserHistory();

export const validateUrl = (string) => {
  const { error } = Joi.string().uri().validate(string);

  return error;
};
